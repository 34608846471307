<template>
  <div>
    <v-toolbar v-if="$vuetify.breakpoint.smAndUp" tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="!loadPage && blog">{{
        blog.title
      }}</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="!loadPage && blog">
      <v-row>
        <v-col class="text-left" :cols="$vuetify.breakpoint.smAndUp ? 12 : ''">
          <v-card
            class="blog-big-card"
          
            :class="$vuetify.breakpoint.xsOnly ? 'mobile-blog-card' : ''"
          >
            <div class="blog-title">{{ blog.title }}</div>
            <div class="blog-head">
              <div class="blog-user-name">
                Автор:
                <router-link
                  :to="{ name: 'UserShow', params: { userName: user.name } }"
                  >{{ user.public_name }}</router-link
                >
              </div>
              <div class="blog-date">Опубликовано: {{ blog.public_date }}</div>
            </div>
            <div class="blog-body" v-html="blog.body"></div>
            <v-card-actions class="mt-auto pl-3 pr-3 pb-3 pt-0">
              <!-- <v-btn
                v-if="currentUser && admin"
                :to="{ name: 'CabinetBlogEdit', params: { id: blog.id } }"
                small
                fab
                light
              >
                <v-icon>$vuetify.icons.square_edit_outline</v-icon>
              </v-btn>
              <v-btn
                @click="addLike"
                small
                :color="inLikes ? 'green darken-3' : ''"
                :light="!inLikes"
                :dark="inLikes"
                :loading="likeLoading"
                fab
              >
                <v-icon>$vuetify.icons.star</v-icon>
              </v-btn>
              <v-btn
                @click="addLibrary"
                small
                :color="inLibrary ? 'green darken-3' : ''"
                :light="!inLibrary"
                :dark="inLibrary"
                :loading="libraryLoading"
                fab
              >
                <v-icon>$vuetify.icons.blogshelf</v-icon>
              </v-btn>

              <v-menu left bottom offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-2" v-on="on" small light fab>
                    <v-icon>$vuetify.icons.dots_horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link>
                    <v-list-item-title @click="dialogReport = true"
                      >Пожаловаться</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu> -->
              <v-spacer></v-spacer>

              <like table_type="blog" :table="blog"></like>
              <library table_type="blog" :table="blog" class="ml-2"></library>
              <right-menu table_type="blog" :table="blog"></right-menu>
            </v-card-actions>
          </v-card>

         

          <v-dialog v-model="dialogReport" class="text-center" max-width="640">
            <v-card>
              <v-card-title class="headline justify-center"
                >Жалоба</v-card-title
              >
              <v-form v-if="currentUser" v-model="validReport" ref="formReport">
                <v-card-text>
                  <v-select
                    v-if="reportTypes && reportTypes.length"
                    v-model="reportType"
                    :items="reportTypes"
                    item-text="title"
                    item-value="id"
                    label="Причина"
                  ></v-select>
                  <v-textarea
                    name="reportMsg"
                    label="Текст жалобы"
                    value
                    v-model="reportMsg"
                    :rules="reportMsgRules"
                  ></v-textarea>
                </v-card-text>
              </v-form>
              <v-card-text class="text-center" v-else>
                Вы должны
                <v-btn
                  :to="{
                    name: 'Login',
                    query: { return_url: $route.fullPath },
                  }"
                  tile
                  link
                  text
                  >авторизоваться</v-btn
                >
              </v-card-text>
              <v-card-actions class="justify-space-around">
                <v-btn
                  v-if="currentUser"
                  color="red darken-1"
                  text
                  @click="sendReport"
                  >Отправить</v-btn
                >

                <v-btn color="green darken-1" text @click="dialogReport = false"
                  >Закрыть</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <comments
            v-if="blog"
            :commentable_id="blogId"
            commentable_type="blog"
            :commentable_slug="blog.slug"
            :entity_user_id="blog.user_id"
            :route_name="$route.name"
          ></comments>
        </v-col>
      </v-row>
      <!-- <no-content
        v-else
        route-name="Login"
        :route-query="{ return_url: $route.fullPath }"
        title="Просматривать блоги могут только авторизованные пользователи"
        button-name="Авторизоваться"
      ></no-content> -->
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Comments from "../comment/Comments.vue";
import RightMenu from "../secondmenu/RightMenu.vue";
import Like from "../secondmenu/Like.vue";
import Library from "../secondmenu/Library.vue";
export default {
  components: { Comments, RightMenu, Like, Library },
  data: () => ({
    // valid: true,
    validReport: true,
    order: null, //Нужно для проверки платежа
    sort: "new",
    sortings: [
      { text: "TOP", val: "top" },
      { text: "По новизне", val: "new" },
      { text: "По популярности", val: "popular" },
      { text: "По комментариям", val: "comments" },
    ],
    page: 1,
    // pages: 0,
    // loadingComments: false,
    tab: null,
    // showCommentAdd: false,
    // commentText: "", //Текст комментария
    // commentTextRules: [
    //   (v) => !!v || "Поле обязательно",
    //   (v) => !!v === v.length >= 1 || "Минимум 1 символ",
    // ],
    libraryLoading: false,
    likeLoading: false,
    // dialogDeleteComment: false,
    // deleteCommentId: null,
    dialogReport: false,

    reportType: 1,
    reportTypes: null,
    reportMsg: "",
    reportMsgRules: [
      // v => !!v || 'Поле обязательно',
      (v) => !!v === v.length >= 10 || "Минимум 10 символов",
    ],
  }),
  metaInfo() {
    if (this.blog) {
      return {
        title: `${this.blog.title} - ${this.user.public_name}`,
        meta: [
          {
            name: "description",
            content: this.metaDescr,
          },
          {
            property: "og:title",
            content: this.blog.title + " - " + this.user.public_name,
          },
          { property: "og:site_name", content: "Bookstab" },
          {
            property: "og:description",
            content: this.metaDescr,
          },
          { property: "og:type", content: "blog" },
          { property: "og:url", content: window.location.href },
          { property: "og:image", content: this.blog.image_big },
        ],
      };
    }
    return {};
  },
  methods: {
    // validate() {
    //   return this.$refs.form.validate();
    // },
    validateReport() {
      return this.$refs.formReport.validate();
    },
    sendReport() {
      // console.log("report", this.validateReport());
      if (!this.validateReport()) return;

      let app = this;
      this.$store
        .dispatch("addReport", {
          body: app.reportMsg,
          reporttable_id: app.blogId,
          reporttable_type: "blog",
          report_type: app.reportType,
        })
        .then((response) => {
          if (response.data.success == 1) {
            app.reportMsg = "";
            app.reportType = 1;
            app.dialogReport = false;
          }
        })
        .catch(() => {});
    },
    getBlog() {
      let app = this;
      this.$store.dispatch("blog/getBlog", {
        blogSlug: app.blogSlug,
        page: app.page,
        sort: app.sort,
        order: app.order,
      });
    },
    addLibrary() {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      let app = this;
      app.libraryLoading = true;
      this.$store
        .dispatch("addLibrary", { blogId: app.blogId })
        .then(() => {
          app.libraryLoading = false;
        })
        .catch(() => {
          app.libraryLoading = false;
        });
    },
    addLike() {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      let app = this;
      app.likeLoading = true;
      this.$store
        .dispatch("addLike", { blogId: app.blogId })
        .then(() => {
          app.likeLoading = false;
        })
        .catch(() => {
          app.likeLoading = false;
        });
    },
    // deleteCommentModal(commentId) {
    //   this.deleteCommentId = commentId;
    //   this.dialogDeleteComment = true;
    // },
    // goPage(nextPage) {
    //   if (nextPage != this.$route.query.page) {
    //     this.$router.push({
    //       name: this.$route.name,
    //       params: { slug: this.blogSlug },
    //       query: { page: nextPage, sort: this.sort },
    //     });
    //   }
    // },
    // submitComment() {
    //   event.preventDefault();
    //   if (!this.validate()) return;
    //   let app = this;

    //   this.$store
    //     .dispatch("comments/addComment", {
    //       body: app.commentText,
    //       commentable_id: app.blogId,
    //       parent_id: null,
    //       commentable_type: "blog",
    //     })
    //     .then(() => {
    //       app.clearComment();
    //     })
    //     .catch(() => {});
    // },

    // clearComment() {
    //   this.commentText = "";
    //   this.showCommentAdd = false;
    // },
    // deleteComment() {
    //   this.loadingComments = true;
    //   let app = this;
    //   this.$store
    //     .dispatch("comments/dellComment", {
    //       comment_id: app.deleteCommentId,
    //       commentable_type: "comment",
    //     })
    //     .then(() => {
    //       app.dialogDeleteComment = false;
    //     })
    //     .catch(() => {
    //       app.dialogDeleteComment = false;
    //     });
    // },
    updateData() {
      if (this.siteSettings) {
        if (
          this.siteSettings.report_types &&
          this.siteSettings.report_types.length > 0
        ) {
          this.reportTypes = this.siteSettings.report_types;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      siteSettings: "siteSettings",
      blog: "blog/blog",
      likes: "blog/likes",
      libraries: "blog/libraries",
      // comments: "comments/comments",
      // comments_pages: "comments/comments_pages",
    }),
    // comments_page: {
    //   get() {
    //     return this.$store.getters["comments/comments_page"];
    //   },
    //   set(val) {
    //     this.$store.commit("comments/SET_PAGE", val);
    //   },
    // },
    user() {
      return this.blog.user;
    },
    blogId() {
      return this.blog.id;
    },

    inLibrary() {
      if (
        this.currentUser &&
        this.libraries.indexOf(this.currentUser.id) >= 0
      ) {
        return true;
      }
      return false;
    },
    inLikes() {
      if (this.currentUser && this.likes.indexOf(this.currentUser.id) >= 0) {
        return true;
      }
      return false;
    },
    libraryButtonText() {
      if (this.inLibrary) {
        return "Удалить из библиотеки";
      }
      return "Добавить в библиотеку";
    },
    likeButtonText() {
      if (this.inLikes) {
        return "Удалить нравится";
      }
      return "Нравится";
    },
    admin() {
      if (
        this.currentUser &&
        this.blog &&
        this.currentUser.id == this.blog.user_id
      ) {
        return true;
      }
      return false;
    },
    metaDescr() {
      if (this.blog && this.blog.excerpt_formating) {
        let size = 160;
        let newText = this.blog.excerpt_formating;
        if (this.blog.excerpt_formating.length > size) {
          newText = this.blog.excerpt_formating.slice(0, size) + " ...";
        }
        return newText;
      }
      return "Читайте литературные блоги авторов и писателей. На Bookstab.ru вас ждет самая правдивая литературная критика в интернете.";
    },
  },
  watch: {
    siteSettings() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
    this.blogSlug = this.$route.params.slug ? this.$route.params.slug : "";
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    // this.sort = this.$route.query.sort ? this.$route.query.sort : "new";
    // this.order = this.$route.query.order ? this.$route.query.order : null;
    this.getBlog();
  },
  created() {
    // this.$eventBus.$on("delete-comment", this.deleteCommentModal);
  },
  beforeDestroy() {
    // if (this.$route.name != "BlogShow") {
    //   //Костыль
    //   this.$eventBus.$off("delete-comment");
    // }
  },
};
</script>
